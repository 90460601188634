import { get, post } from '../helper/API';

export function getAssessmentsList() {
    return get('employer/company/auditions');
}

export function getCompanyInsights(params, publicLinkUuid) {
    if (publicLinkUuid) return get(`public/company/insight/${publicLinkUuid}`);
    return get('employer/company/insight', params);
}

export function getSkillsBreakdown(params, publicLinkUuid) {
    if (publicLinkUuid) return get(`public/company/insight/${publicLinkUuid}/skills`);
    return get('employer/company/insight/skills', params);
}

export function getHireData(params, publicLinkUuid) {
    if (publicLinkUuid) return get(`public/company/insight/${publicLinkUuid}/hire`);
    return get('employer/company/insight/hire', params);
}

export function getHireFeedbacks(params) {
    return get('employer/company/insight/hire/feedbacks', params);
}

export function getFiltersForHireFeedbacks(params) {
    return get('employer/company/insight/hire/filters-for-feedback', params);
}

export function exportHireFeedbackCsv(params) {
    return get('employer/company/insight/hire/feedbacks/export', params);
}


export function getInsightAuditions(params, publicLinkUuid) {
    if (publicLinkUuid) return get(`public/company/insight/${publicLinkUuid}/auditions`);
    return get('employer/company/insight/auditions', params);
}

export function getCandidateCsat(params, publicLinkUuid) {
    if (publicLinkUuid) return get(`public/company/insight/${publicLinkUuid}/csat`);
    return get('employer/company/insight/csat', params);
}

export function getCsatFeedbacks(params) {
    return get('employer/company/insight/csat/feedbacks', params);
}

export function getFiltersForCsatFeedbacks(params) {
    return get('employer/company/insight/csat/filters-for-feedback', params);
}

export function exportCsatFeedbackCsv(params) {
    return get('employer/company/insight/csat/feedbacks/export', params);
}

export function getCandidateCompletions(params, publicLinkUuid) {
    if (publicLinkUuid) return get(`public/company/insight/${publicLinkUuid}/candidate-completions`);
    return get('employer/company/insight/candidate-completions', params);
}

export function exportCompletionsCsv(params) {
    return get('employer/company/insight/candidate-completions/export', params);
}

export function getScoreDistribution(params, publicLinkUuid) {
    if (publicLinkUuid) return get(`public/company/insight/${publicLinkUuid}/score-distribution`);
    return get('employer/company/insight/score-distribution', params);
}

export function exportInsightsScoreDistribution(params) {
    return get('employer/company/insight/score-distribution/export', params);
}

export function createShareLink(params) {
    const query = new URLSearchParams(params).toString();
    return post(`employer/company/insight/share-links?${query}`);
}

export function getShareDates(linkUuid) {
    return get(`public/company/insight/share-links/${linkUuid}`);
}

export function exportInsightAuditions(params) {
    return get('employer/company/insight/auditions/export', params);
}

export function getEeocDistribution(params, publicLinkUuid) {
    if (publicLinkUuid) return get(`public/company/insight/${publicLinkUuid}/eeoc`);
    return get('employer/company/insight/eeoc', params);
}
