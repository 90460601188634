import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import ButtonBase from '@mui/material/ButtonBase';
import Zendesk from 'helper/zendeskFunctions';
import { accountInsightsStoreCtx } from '../../store';
import styles from './styles';
import GraphUpIcon from './img/graphUp.svg';
import GraphDownIcon from './img/graphDown.svg';

const TrendIndicator = ({ trend, classes }) => {
    if (!trend || trend === 0) return null;

    const isPositive = trend > 0;
    return (
        <>
            <img src={isPositive ? GraphUpIcon : GraphDownIcon} alt={isPositive ? 'Graph Up' : 'Graph Down'} />
            <span className={isPositive ? classes.green : classes.red}>{Math.abs(trend)}%</span>
            {isPositive ? 'Up' : 'Down'} from last month
        </>
    );
};

const StatisticBlock = ({ title, value, trend, showTrend, classes, children, asConstant, suffix = '', showNoData }) => (
    <div className={clsx(classes.block, asConstant && classes.constantBlock)}>
        <span className={classes.title}>{title}</span>
        <div className={classes.valueBlock}>
            <span className={clsx(classes.value, asConstant && classes.constant)}>{value}{suffix}</span>
            <div className={classes.valueBottom}>
                {showTrend && trend !== null && trend !== 0 && (
                    <TrendIndicator trend={trend} classes={classes} />
                )}
                {children}
                {
                    (value === 0 || value === '-') && showNoData && (
                        <div className={classes.noData}>No data available</div>
                    )
                }
            </div>
        </div>
    </div>
);

const TopStatisticBar = observer(({ classes, isPublic }) => {
    const { dateFilterType, companyInsights } = useContext(accountInsightsStoreCtx);

    const showTrend = true; // Temporary solution for QA, should be removed
    // const showTrend = dateFilterType === DATE_RANGES.LAST_30_DAYS;

    if (!companyInsights) return null;

    const {
        activeUsersCount, requestCustomAssessmentsCount, candidateCSATSTrend, candidateCSATScore, candidateProcessedCount,
        candidateProcessedTrend, rolesAssessedCount, rolesAssessedTrend, timeSavedHours, timeSavedTrend
    } = companyInsights;

    return (
        <div className={clsx('u-dsp--f', classes.wrapper)}>
            <StatisticBlock title="ACTIVE USERS" value={activeUsersCount} classes={classes} asConstant />
            <StatisticBlock
                title="CUSTOM ASSESSMENT CREDITS"
                value={requestCustomAssessmentsCount}
                classes={classes}
                asConstant
            >
                {!requestCustomAssessmentsCount && !isPublic && (
                    <ButtonBase
                        className={classes.baseButton}
                        onClick={() => Zendesk.open(true)}
                    >
                        Talk to Sales
                    </ButtonBase>
                )}
            </StatisticBlock>
            <StatisticBlock
                title="ROLES ASSESSED"
                value={rolesAssessedCount}
                trend={rolesAssessedTrend}
                showTrend={showTrend}
                classes={classes}
                showNoData
            />
            <StatisticBlock
                title="CANDIDATES PROCESSED"
                value={candidateProcessedCount}
                trend={candidateProcessedTrend}
                showTrend={showTrend}
                classes={classes}
                showNoData
            />
            <StatisticBlock
                title="TIME SAVED"
                value={timeSavedHours || '-'}
                suffix={timeSavedHours ? ' hours' : ''}
                trend={timeSavedTrend}
                showTrend={showTrend}
                classes={classes}
                showNoData
            />
            <StatisticBlock
                title="CANDIDATE CSAT SCORE"
                value={candidateCSATScore || '-'}
                trend={candidateCSATSTrend}
                showTrend={showTrend}
                classes={classes}
                showNoData
            />
        </div>
    );
});

export default withStyles(styles)(TopStatisticBar);
