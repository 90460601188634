export default theme => ({
    // Page Itself Styles
    contentWrapper: {
        padding: 0,
        marginBottom: 0,
        backgroundColor: 'transparent',
        border: 'none'
    },
    baseButton: {
        color: theme.palette.blue[500],
        fontWeight: 600,
        fontSize: 14
    },
    loaderWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    headerTitle: {
        fontSize: 20,
        fontWeight: 700,
        color: '#000'
    },

    // General Page Filter Styles
    assessmentSelect: {
        width: 235
    }
});
