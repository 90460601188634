import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TimeRange from 'components/insight_components/time_range';
import { withStyles } from '@mui/styles';
import { DATE_RANGES } from 'helper/constants';
import Select from 'libraries/Select';
import Tooltip from 'libraries/Tooltip';
import { getAssessmentsList, createShareLink } from 'requests/AccountInsightsRequests';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { appCtx } from 'components/appStore';
import { copyTextToClipboard } from 'helper/commonFunctions';
import InsightEvents from 'events/InsightEvents';
import { accountInsightsStoreCtx } from '../../store';
import styles from '../../styles';
import { DISABLED_TOOLTIP_TEXT } from '../../constants';

const GeneralPageFilter = observer(({ classes, isPublic }) => {
    const [assessment, setAssessment] = useState('all');
    const [options, setOptions] = useState([{ value: 'all', label: 'All Assessments ' }]);

    const { flashMessage } = useContext(appCtx);
    const { setTimeRange, setSelectedAssessment, baseParams, timeRange, isTeamUser } = useContext(accountInsightsStoreCtx);

    useEffect(() => {
        if (isPublic) return;

        getAssessmentsList().then(({ success, data }) => {
            if (success && data) {
                const newOptions = data.map(item => ({ value: item.slug, label: item.name }));
                setOptions(prev => prev.concat(newOptions));
            }
        });

        return () => {
            setSelectedAssessment('all');
        };
    }, []);

    const onShareInsights = () => {
        createShareLink(baseParams).then(({ uuid }) => {
            const { protocol, host } = window.location;
            const invitationLink = `${protocol}//${host}/account-insights/${uuid}/shared`;
            copyTextToClipboard(invitationLink, () => { flashMessage('Link copied to clipboard', 'done'); });
            InsightEvents.ACCOUNT_INSIGHTS_SHARED();
        });
    };

    return (
        <div className={clsx('u-dsp--f u-z--10 u-fw--wrap', isPublic && 'u-jc--end u-mrg--bx3')}>
            <div className="u-dsp--f u-mrg--rx3">
                <TimeRange
                    handleDateRange={setTimeRange}
                    disabled={isPublic}
                    availableOptions={isPublic ? [] : [DATE_RANGES.LAST_7_DAYS, DATE_RANGES.LAST_30_DAYS, DATE_RANGES.LAST_YEAR]}
                    defaultRange={(isPublic && timeRange) ? Object.values(timeRange) : DATE_RANGES.LAST_30_DAYS}
                    limitOneYear
                />
            </div>

            <Tooltip
                label={isTeamUser ? DISABLED_TOOLTIP_TEXT : null}
            >
                <div>
                    <Select
                        variant="outlined"
                        margin="none"
                        value={assessment}
                        label="Assessment"
                        className={classes.assessmentSelect}
                        onChange={(e) => {
                            setAssessment(e.target.value);
                            setSelectedAssessment(e.target.value);
                        }}
                        options={options}
                        disabled={isPublic || isTeamUser}
                    />
                </div>
            </Tooltip>
            {
                !isPublic && (
                    <Button className={clsx(classes.baseButton, 'u-mrg--lx3')} onClick={onShareInsights}>
                        Share Insights
                    </Button>
                )
            }
        </div>
    );
});

export default withStyles(styles)(GeneralPageFilter);
