import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { PieChart, Pie } from 'recharts';
import clsx from 'clsx';
import { formatTimestamp, getInitials } from 'helper/commonFunctions';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import ButtonBase from '@mui/material/ButtonBase';
import FeedbackModal from 'components/insight_components/feedback_modal';
import {
    exportHireFeedbackCsv, getFiltersForHireFeedbacks, getHireFeedbacks
} from 'requests/AccountInsightsRequests';
import NoDataIcon from 'img/no_data.svg';
import useStyles from '../../styles';
import { DISABLED_TOOLTIP_TEXT } from '../../../../constants';

const EMPTY_CHART_DATA = [
    { value: 80, fill: '#F4F4F7' },
    { value: 20, fill: '#F4F4F7' }
];

const HireData = observer(({ data, generalFilterParams, isPublic, isTeamUser }) => {
    const [dialogOpened, setDialogOpened] = useState(false);

    const { metricsWrapper, divider, hireChartsWrapper, hirePieDescription, hireStatBlock, noFeedbacks,
        hireStatsWrapper, linkClassName, sectionTitle, initials, hireFeedback, dataUnavailable, statTitle, readMore } = useStyles();
    const { timeSaved, rehiredCandidatesRate, roles, averageRehiredCandidatesRate, averageTimeFiledRolesInDays, timeFiledRolesInDays,
        retentionRate, averageRetentionRate, feedbacks } = data;

    const handleDialog = () => {
        setDialogOpened(!dialogOpened);
    };

    const renderDataUnavailable = () => (
        <div className={clsx(dataUnavailable, 'u-dsp--f u-ai--center')}>
            <img src={NoDataIcon} alt="No data" />
            <p>Data unavailable</p>
        </div>
    );

    const readMoreButton = () => (
        <ButtonBase
            className={clsx(linkClassName, readMore)}
            onClick={() => {}}
        >
            Read more
        </ButtonBase>
    );

    const hasTimeSavedData = timeSaved > 0;
    const hasRehiredCandidatesRateData = rehiredCandidatesRate > 0;
    const hasTimeFiledRolesInDaysData = timeFiledRolesInDays > 0;
    const hasRetentionRateData = retentionRate > 0;
    const hasFeedbacks = feedbacks && feedbacks.length > 0;
    const chartWidth = 150;

    return (
        <>
            <div className={metricsWrapper}>
                <div className={hireChartsWrapper}>
                    <div className="u-dsp--f u-ai--center">
                        <PieChart width={chartWidth} height={chartWidth}>
                            <Pie
                                dataKey="value"
                                data={hasTimeSavedData > 0 ? [
                                    { value: timeSaved, fill: '#009879' },
                                    { value: 100 - timeSaved, fill: '#F4F4F7' }
                                ] : EMPTY_CHART_DATA}
                                strokeWidth={hasTimeSavedData ? 1 : 0}
                                isAnimationActive={false}
                            />
                        </PieChart>
                        <div className={hirePieDescription}>
                            {
                                hasTimeSavedData ? (
                                    <>
                                        <p><span>{timeSaved}%</span> Time saved</p>
                                        <p>filling <span>{roles}</span> roles</p>
                                    </>
                                ) : renderDataUnavailable()
                            }
                        </div>
                    </div>
                    <div className="u-dsp--f u-ai--center">
                        <PieChart width={chartWidth} height={chartWidth}>
                            <Pie
                                dataKey="value"
                                data={hasRehiredCandidatesRateData ? [
                                    { value: rehiredCandidatesRate, fill: '#009879' },
                                    { value: 100 - rehiredCandidatesRate, fill: '#F4F4F7' }
                                ] : EMPTY_CHART_DATA}
                                strokeWidth={hasRehiredCandidatesRateData ? 1 : 0}
                                isAnimationActive={false}
                            />
                        </PieChart>
                        <div className={hirePieDescription}>
                            {
                                hasRehiredCandidatesRateData ? (
                                    <>
                                        <p><span>{rehiredCandidatesRate}%</span> Candidates</p>
                                        <p>would be rehired</p>
                                        <p className="averageDesc">Average <span>{averageRehiredCandidatesRate} %</span></p>
                                    </>
                                ) : (
                                    <>
                                        <p className={statTitle}>QUALITY OF HIRE DATA</p>
                                        {renderDataUnavailable()}
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className={divider} />
                <div className={clsx(hireStatsWrapper, 'u-dsp--f u-fdir--column u-jc--sb')}>
                    <div className={hireStatBlock}>
                        <p className={statTitle}>AVERAGE TIME TO FILL A ROLE</p>
                        {
                            hasTimeFiledRolesInDaysData ? (
                                <>
                                    <p className="average">Average <span>{averageTimeFiledRolesInDays} days</span></p>
                                    <p className="value">{timeFiledRolesInDays} days</p>
                                </>
                            ) : (
                                <div className="u-mrg--tx1">
                                    {renderDataUnavailable()}
                                </div>
                            )
                        }
                    </div>
                    <div className={hireStatBlock}>
                        <p className={statTitle}>RETENTION RATE</p>
                        {
                            hasRetentionRateData ? (
                                <>
                                    <p className="value">{retentionRate} %</p>
                                    <p className="average">Average <span>{averageRetentionRate}%</span></p>
                                </>
                            ) : (
                                <div className="u-mrg--tx1">
                                    {renderDataUnavailable()}
                                    {readMoreButton()}
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className={divider} />
                <div className="u-wdt--100p u-dsp--f u-fdir--column u-jc--start">
                    <div className="u-dsp--f u-ai--center u-jc--sb u-mrg--bx2">
                        <p className={sectionTitle}>HIRING MANAGER FEEDBACK</p>
                        {
                            hasFeedbacks && !isPublic && (
                                <ButtonBase
                                    className={linkClassName}
                                    onClick={handleDialog}
                                >
                                    View all
                                </ButtonBase>
                            )
                        }
                    </div>
                    <div style={{ minHeight: 235 }}>
                        {
                            hasFeedbacks ? feedbacks.map((feedback, idx) => (
                                <div className={clsx(hireFeedback, 'u-dsp--f u-fdir--column')} key={idx}>
                                    <div className="u-dsp--f u-jc--sb u-mrg--bx1 u-ai--center">
                                        <p className="name"><span className={initials}>{getInitials(feedback.candidateFullName)}</span>  {feedback.candidateFullName}</p>
                                        <p className="role"><TruncateWithTooltip text={feedback.auditionName} width={300} /></p>
                                    </div>
                                    <div className="u-dsp--f u-jc--sb u-mrg--bx1">
                                        <p className="manager">HIRING MANAGER: {feedback.hiringManagerFullName}</p>
                                        <p className="score">HIRING SCORE <span>{feedback.rating}</span></p>
                                    </div>
                                    <div className="feedback">
                                        <TruncateWithTooltip text={feedback.comment} width={1000} rows={2} />
                                    </div>
                                    <div className="date">{formatTimestamp(feedback.createdAt)}</div>
                                </div>
                            )) : (
                                <div className={noFeedbacks}>
                                    <img src={NoDataIcon} alt="" />
                                    <span>No Data Available</span>
                                    {readMoreButton()}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <FeedbackModal
                open={dialogOpened}
                onClose={handleDialog}
                canDownloadCSV
                disabledDownloadCsv={isTeamUser ? DISABLED_TOOLTIP_TEXT : null}
                loadParams={{ ...generalFilterParams }}
                loadDataRequest={getHireFeedbacks}
                loadFilterRequest={getFiltersForHireFeedbacks}
                requestExportCsv={exportHireFeedbackCsv}
                title="Hiring Manager Feedback"
                maxWidth="md"
                csvSlug="Account-insights-hiring-manager-feedback"
                renderItem={({ candidateFullName, auditionName, hiringManagerFullName, rating, comment, createdAt, wouldYouHireAgain }, idx) => (
                    <div className={clsx(hireFeedback, 'u-dsp--f u-fdir--column inmodal')} key={idx}>
                        <div className="u-dsp--f u-jc--sb u-mrg--bx1">
                            <p className="name"><span className={clsx(initials, 'manager')}>{getInitials(hiringManagerFullName)}</span> {hiringManagerFullName}</p>
                            <p className="score">HIRING SCORE <span>{rating}</span></p>
                        </div>
                        <div className="hire_again u-mrg--bx1">
                            Would you hire again? <span>{wouldYouHireAgain}</span>
                        </div>
                        <div className="feedback u-mrg--bx1 feedback">
                            {comment}
                        </div>
                        <div className="u-dsp--f u-ai--center u-mrg--bx1">
                            <div className="u-mrg--rx2">CANDIDATE: </div>
                            <div className="u-dsp--f u-ai--center">
                                <p className="name"><span className={initials}>{getInitials(candidateFullName)}</span></p>
                                <p className="name">{candidateFullName}</p>
                            </div>
                        </div>
                        <p className="role u-dsp--f u-ai--center">Assessment:&nbsp; <TruncateWithTooltip text={auditionName} width={1000} /></p>
                        <div className="date">{formatTimestamp(createdAt)}</div>
                    </div>
                )}
            />
        </>
    );
});

export default HireData;
