import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: 30,
        backgroundColor: 'white',
        padding: 30,
        height: 375,
        display: 'flex'
    },
    title: {
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.grey[800],
        marginTop: -26
    },
    pieTitle: {
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.grey[800]
    },
    genderTitle: {
        fontSize: 12,
        color: theme.palette.grey[800],
        fontWeight: 700,
        position: 'absolute',
        top: 0
    },
    divider: {
        width: 1,
        height: '100%',
        backgroundColor: theme.palette.grey[300],
        marginRight: 10,
        marginLeft: 10,
        '@media (max-width: 1230px)': {
            marginRight: 5,
            marginLeft: 5
        }
    },
    iconRow: {
        marginBottom: 11
    },
    genderColumn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minWidth: 50,
        width: 150,
        position: 'relative'
    },
    dataUnavailable: {
        display: 'flex',
        alignItems: 'center',
        gap: 14,
        fontSize: 18,
        color: theme.palette.grey[700],
        marginTop: 50,
        marginBottom: 50,
        fontWeight: 700,
        '& img': {
            width: 40
        }
    },
    noDataWrapper: {
        minWidth: 270
    }
}));

export default useStyles;
