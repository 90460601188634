import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Pie, PieChart } from 'recharts';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import { capitalizeFirstLetter } from 'helper/commonFunctions';
import Tooltip from 'libraries/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import NoDataIcon from 'img/no_data.svg';
import { accountInsightsStoreCtx } from '../../store';
import useStyles from './styles';
import useMetricStyles from '../DataMetrics/styles';
import PassIcon from './img/pass_icon.svg';
import FailIcon from './img/fail_icon.svg';
import NeutralIcon from './img/neutral_icon.svg';

const GENDER_COLORS = ['#00A98A', '#503DD1', '#8B76E0', '#CEC5F1'];
const RACE_COLORS = ['#503DD1', '#8B76E0', '#FFE490', '#FFC841', '#F87E30', '#00C5A8', '#7ADFCE', '#7794FC', '#CEDCFE'];
const POSSIBLE_RACES = ['Hispanic or Latino', 'White', 'Black or African American',
    'Native Hawaiian or Other Pacific Islander', 'Asian', 'American Indian or Alaskan Native',
    'Two or More Races', 'Other', 'Prefer not to answer'];

const EeocDistribution = observer(({ isPublic }) => {
    const { eeocDistributionData } = useContext(accountInsightsStoreCtx);
    const { wrapper, title, genderTitle, pieTitle, divider, iconRow, genderColumn, dataUnavailable, noDataWrapper } = useStyles();
    const { circle, skillRow } = useMetricStyles();

    if (!eeocDistributionData || isPublic) {
        return null;
    }

    const { distribution, details } = eeocDistributionData;

    const genderChartData = useMemo(() => distribution?.map((el, i) => ({
        name: capitalizeFirstLetter(el.gender),
        value: el.count,
        fill: GENDER_COLORS[i]
    })), [eeocDistributionData]);

    const raceData = useMemo(() => {
        const raceMap = POSSIBLE_RACES.reduce((acc, race) => ({
            ...acc,
            [race.toLowerCase()]: { count: 0, genders: { male: null, female: null, others: null }, value: race }
        }), {});

        details.forEach(({ race, gender, count, status }) => {
            const currentRace = raceMap[race];

            if (currentRace) {
                currentRace.count += count;
                currentRace.genders[gender] = status;
            }
        });

        return raceMap;
    }, [eeocDistributionData]);

    const raceChartData = useMemo(() => Object.keys(raceData).map((race, i) => ({
        name: race,
        value: raceData[race].count,
        fill: RACE_COLORS[i % RACE_COLORS.length]
    })), [raceData]);

    const renderIcon = (value) => {
        if (value === null || value === undefined) {
            return <Tooltip label="Neutral"><img src={NeutralIcon} alt="Neutral" /></Tooltip>;
        }
        if (value === true) {
            return <Tooltip label="Pass"><img src={PassIcon} alt="Pass" /></Tooltip>;
        }
        return <Tooltip label="Fail"><img src={FailIcon} alt="Fail" /></Tooltip>;
    };

    const noData = () => (
        <div className={clsx(dataUnavailable, 'u-dsp--f u-fdir--column u-ai--center')}>
            <img src={NoDataIcon} alt="No data" />
            <p>No Data Available</p>
        </div>
    );

    const isTablet = useMediaQuery('(max-width: 1230px)');
    const isGenderData = genderChartData.length > 0;
    const isDetailsData = details.length > 0;
    return (
        <div className={wrapper}>
            <div className={clsx('u-dsp--f u-ai--center u-jc--center', !isGenderData && noDataWrapper)}>
                <div className="u-dsp--f u-fdir--column u-ai--center">
                    <div className={title}>EEOC Distribution</div>
                    {
                        isGenderData ? (
                            <PieChart width={230} height={230}>
                                <Pie
                                    dataKey="value"
                                    data={genderChartData}
                                    strokeWidth={2}
                                    isAnimationActive={false}
                                />
                            </PieChart>
                        ) : noData()
                    }
                    <span className={pieTitle}>GENDER</span>
                </div>
                {
                    isGenderData && (
                        <div className="u-mrg--tx10 u-wdt--100">
                            {distribution.map((dist, i) => (
                                <div key={i} className={skillRow}>
                                    <span className={circle} style={{ backgroundColor: GENDER_COLORS[i] }} />
                                    <TruncateWithTooltip text={capitalizeFirstLetter(dist.gender)} width={200} rows={2} />
                                </div>
                            ))}
                        </div>
                    )
                }
            </div>
            <div className={divider} />
            <div className="u-dsp--f u-ai--center u-jc--center u-wdt--100p">
                <div className="u-dsp--f u-fdir--column u-ai--center">
                    {
                        isDetailsData ? (
                            <PieChart width={230} height={230}>
                                <Pie
                                    dataKey="value"
                                    data={raceChartData}
                                    strokeWidth={2}
                                    isAnimationActive={false}
                                />
                            </PieChart>
                        ) : noData()
                    }
                    <span className={pieTitle}>RACE/ETHNICITY</span>
                </div>
                <div>
                    {isDetailsData && Object.keys(raceData).map((race, i) => (
                        <div key={i} className={skillRow}>
                            <span className={circle} style={{ backgroundColor: RACE_COLORS[i % RACE_COLORS.length] }} />
                            <TruncateWithTooltip text={raceData[race].value} width={isTablet ? 70 : 400} />
                        </div>
                    ))}
                </div>
            </div>
            {
                isDetailsData && (
                    <>
                        <div className={divider} />
                        <div className={genderColumn}>
                            <div className={genderTitle}>MALE</div>
                            {
                                Object.keys(raceData).map((race, i) => (
                                    <div key={i} className={iconRow}>{renderIcon(raceData[race].genders.male)}</div>
                                ))
                            }
                        </div>
                        <div className={divider} />
                        <div className={genderColumn}>
                            <div className={genderTitle}>FEMALE</div>
                            {
                                Object.keys(raceData).map((race, i) => (
                                    <div key={i} className={iconRow}>{renderIcon(raceData[race].genders.female)}</div>
                                ))
                            }
                        </div>
                        <div className={divider} />
                        <div className={genderColumn}>
                            <div className={genderTitle}>OTHERS</div>
                            {
                                Object.keys(raceData).map((race, i) => (
                                    <div key={i} className={iconRow}>{renderIcon(raceData[race].genders.others)}</div>
                                ))
                            }
                        </div>
                    </>
                )
            }
        </div>
    );
});

export default EeocDistribution;
